type SubscriptionStatus =
  | 'Not subscribed'
  | 'Legacy'
  | 'Active'
  | 'Expired'
  | 'Will expire';

export function getSubscriptionStatus(
  subscription?: UserSubscription,
): SubscriptionStatus {
  if (!subscription) {
    return 'Not subscribed';
  }

  const { stripe, isLegacySubscription, apple } = subscription;
  const now = Date.now();

  // If subscription expiration time is either 9999999999999 or exists without a customerId, it's a legacy PayPal subscription
  if (isLegacySubscription) {
    return 'Legacy';
  }

  // If subscription expiration time is not set, user is not subscribed yet.
  if (
    !stripe?.subscriptionExpirationTime &&
    !apple?.subscriptionExpirationTime
  ) {
    return 'Not subscribed';
  }

  const expiredStripe = (stripe?.subscriptionExpirationTime || 0) < now;
  const expiredApple = (apple?.subscriptionExpirationTime || 0) < now;
  if (!expiredStripe) return stripe?.active ? 'Active' : 'Will expire';

  const active = !expiredApple || !expiredStripe;
  return active ? 'Active' : 'Expired';
}

export function getSubscriptionBadgeClass(status: SubscriptionStatus) {
  if (status === 'Not subscribed' || status === 'Expired') {
    return 'badge badge--red';
  }

  return status === 'Will expire'
    ? 'badge badge--orange'
    : 'badge badge--green';
}
